.hr-sect {
    display: flex;
  flex-basis: 100%;
  align-items: center;
  /*color: rgba(255, 255, 255, 0.85);*/
  color: rgba(208, 0, 255, 0.85);
  margin: 1px 0px;
}

.hr-sect::before, 
.hr-sect::after {
  content: '';
  flex-grow: 1;
  /*background: rgba(255, 255, 255, 0.85);*/
  background: rgba(208, 0, 255, 0.85);
  height: 1px;
  font-size: 0px;
  line-height: 0px;
  margin: 0px 1px;
}