h1, p {
    font-family: Lato;
  }
  
  /* .modal-open .modal {
      overflow-x: hidden;
      overflow-y: auto;
  } */
  
  .modal {
      /* position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1050;
      display: none;
      overflow: hidden;
      outline: 0; */
  }
  
  .modal-dialog {
      /* position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%) !important;
      transform: translate(-50%, -50%) !important;
      min-width: 500px; */
  }
  /* 
  .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%; 
    pointer-events: auto;
    background-color: gray;
    background-clip: padding-box;
    border: black;
  }
  
  
  .modal-header {
    display: flex;
    align-items: flex-start; 
    justify-content: space-between; 
  
  }
  
    .close {
      padding: 0;
      
      margin: 0;
    }
  
  .modal-body {
    position: relative;
    flex: 1 1 auto;
  }
  
  .fade {
      transition: opacity 0.15s linear;
  } */
  